<template>
  <div id="content" ref="content" class="home">

    <div id="hero" class="hero-wrapper">
      <b-container>
        <b-row align-v="center" class="hero">
          <b-col cols="12" md="8">
            <div class="hero-content">
              <div class="hero-headline">
                <h4>RAIL SERVICE CENTER DORTMUND-EVING</h4>
                <h1>My Rail Services</h1>
                <p>Fragen Sie jetzt schnell und unkompliziert Ihre nächste Instandhaltung an.</p>
              </div>
              <!--              <b-button href="#step-1" variant="outline-primary">Mehr erfahren</b-button>-->
              <b-button class="ml-1" href="#step-2" light style="padding:11px 20px" variant="primary">Direkt anfragen
              </b-button>
            </div>
          </b-col>
          <b-col cols="12" md="4"></b-col>
        </b-row>
      </b-container>
    </div>
    <b-container id="step-1" style="background:#000028;color:white;">
      <b-row class="mt-5 pt-5 mb-5 pb-5">

        <b-col>
          <div class="headline">
            <h5>Schnell und unkompliziert </h5>
            <h3>In drei Schritten Ihren Service online anfragen.</h3>
          </div>
          <p>
            Unser Serviceangebot umfasst sämtliche Aspekte rund um die Inspektion und Instandsetzung Ihrer
            Schienenfahrzeuge. Modernste Prüf- und Instandhaltungseinrichtungen sorgen für effiziente Organisation und
            schnelle und kompetente Leistungserbringung für kürzeste Stillstandzeiten Ihrer Fahrzeuge. Ebenso schnell
            und unkompliziert gestalten wir für Sie die Anfrage Ihrer Serviceanliegen in drei einfachen Schritten:
          </p>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="mt-5" style="color:white">
      <b-row class="text-center">
        <b-col cols="12" md="4">
          <b-avatar class="border  border-primary bg-transparent text-primary" size="7rem" variant="primary">
            <span class="icon-Check" style="font-size:3rem"></span>
          </b-avatar>
          <h3>1. Servicewahl</h3>
          <p>Wählen Sie aus einer
            Vielzahl von Leistungen
            Ihr persönliches
            Instandhaltungsanliegen
            aus.</p>
        </b-col>
        <b-col class="mt-3 mt-sm-0" cols="12" md="4">
          <b-avatar class="border  border-primary bg-transparent text-primary" size="7rem" variant="primary">
            <span class="icon-Calender" style="font-size:2rem"></span>
          </b-avatar>
          <h3>2. Termin</h3>
          <p>Fragen Sie online rund
            um die Uhr Ihren
            Wunschtermin an – 24
            Stunden am Tag, 365
            Tage im Jahr.</p>
        </b-col>
        <b-col class="mt-3 mt-sm-0" cols="12" md="4">
          <b-avatar class="border  border-primary bg-transparent text-primary" size="7rem" variant="primary">
            <span class="icon-Email" style="font-size:2rem"></span>
          </b-avatar>
          <h3>3. Anfrage</h3>
          <p>Wir prüfen Ihre
            Anfrage und kontaktieren Sie
            umgehend zur weiteren
            Abstimmung. Innerhalb von
            48 Stunden erhalten Sie
            eine Rückmeldung.</p>
        </b-col>
      </b-row>
    </b-container>

    <b-container id="step-2" class="text-white pt-5 mt-5">
      <b-row>
        <b-col>
          <div class="headline">
            <h5>DATENEINGABE</h5>
            <h3>Bitte geben Sie folgende Daten an</h3>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h5 class="mt-5">Kategorie Ihres Schienenfahrzeuges</h5>
          <b-button
              v-for="category in availableTrainCategories" :key="category._id"
              :class="{ active: selectedTrainCategory===category._id }"
              class="mt-3 category-btn"
              variant="outline-primary"
              @click="setSelectedTrainCategory(category._id)"
          >{{ category.name }}
          </b-button>
        </b-col>
      </b-row>
      <additional-information-form></additional-information-form>
    </b-container>
    <b-container id="step-services" class="text-white pt-sm-5 mt-sm-5">
      <b-row>
        <b-col>
          <div class="headline">
            <h5>Service</h5>
            <h3>Bitte wählen Sie einen Service</h3>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-for="(serviceCategory) in availableServiceCategories"
               :key="serviceCategory._id"
               class="mb-4"
               cols="12"
               sm="6"
        >
          <b-card
              :img-alt="serviceCategory.name"
              :img-src="serviceCategory.image"
              :title="serviceCategory.name"
              overlay
              sub-title-text-variant="white"
              text-variant="white"
              @mouseleave="leaveServiceCategory(serviceCategory._id)"
              @mouseover="enterServiceCategory(serviceCategory._id)"
          >
            <b-card-text v-if="hoveredServiceCategory===serviceCategory._id"
                         :class="{ 'card-margin': serviceCategory._id === '60fef20df5e29164fa8e6cc4'}"
            >

              <div v-if="serviceCategory._id === '60fef254cc860b651b1f5d7b' && serviceSelectionStepCor === 1">
                <b-form-group
                    id="input-group-1"
                    class="w-100"
                    label="Leistung:"
                    label-for="maintenanceLevel"
                >
                  <b-form-textarea id="miscCor"
                                   v-model="miscCor"
                                   v-b-tooltip.hover
                                   placeholder="Leistung"
                                   required
                                   title="Geben Sie hier die Leistung im Bereich der korrektiven Instandhaltungsmaßnahmen an, die Sie anfragen möchten."
                                   type="text"
                  ></b-form-textarea>
                </b-form-group>
              </div>
              <div v-if="serviceCategory._id === '60fef249e84194651381b822'">

                <b-form-row>
                  <b-col>
                    <b-form-input id="maintenanceLevel"
                                  v-model="maintenanceLevel"
                                  class="w-100"
                                  placeholder="Instandhaltungsstufe"
                                  required

                    ></b-form-input>
                  </b-col>
                  <b-col>
                    <b-form-input id="miscPre"
                                  v-model="miscPre"
                                  class="w-100"
                                  placeholder="Sonstiges"
                                  required
                                  type="text"
                    ></b-form-input>
                  </b-col>
                </b-form-row>

              </div>
              <div>
                <b-form-row>
                  <b-col
                      v-for="service in servicesByCategory(serviceCategory._id)"
                      :key="service._id"
                      v-b-tooltip.hover :cols="service.cols"
                      :title="(service.tooltip && !serviceAvailable(service._id))?service.tooltip.title:''"
                  >
                    <b-button
                        :class="{ active: (selectedServices.indexOf(service._id) > -1) }"
                        :disabled="!serviceAvailable(service._id)"
                        class="mt-3 mr-1 w-100"
                        variant="outline-primary"
                        @click="toggleSelectedService(service._id)"
                    >
                      {{ service.name }}
                    </b-button>
                  </b-col>
                </b-form-row>
              </div>
            </b-card-text>
            <div v-if="hoveredServiceCategory===serviceCategory._id">
              <b-button v-if="serviceSelectionStepCor === 2 || serviceSelectionStepPre === 2"
                        class="mt-5"
                        style="position:absolute;bottom:20px;left:20px;"
                        variant="outline-light"
                        @click="serviceSelectionStepCor = 1;serviceSelectionStepPre = 1"
              >
                Zurück
              </b-button>
              <!--              <b-button v-if="serviceCategory.id === 3 && serviceSelectionStepPre === 1"-->
              <!--                        class="mt-5"-->
              <!--                        style="position:absolute;bottom:20px;right:20px;"-->
              <!--                        variant="primary"-->
              <!--                        @click="serviceSelectionStepPre = 2">Weiter-->
              <!--              </b-button>-->
              <b-button v-else
                        :disabled="!canBeAddedToCart(serviceCategory._id)"
                        class="mt-5 choose-btn"
                        style="position:absolute;bottom:20px;right:20px;"
                        variant="primary"
                        @click="addServicesToCart(serviceCategory._id)"
              >
                Auswählen
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <div class="navigation-dots">
      <b-nav v-b-scrollspy vertical>
        <b-nav-item class="p-0" href="#hero"><span class="navigation-dot"></span></b-nav-item>
        <b-nav-item class="p-0" href="#step-1"><span class="navigation-dot"></span></b-nav-item>
        <b-nav-item class="p-0" href="#step-2"><span class="navigation-dot"></span></b-nav-item>
      </b-nav>
    </div>
    <b-container>
      <div class="text-right">
        <b-button :disabled="orderItems.length === 0" variant="primary" @click="chooseDate">Weiter
        </b-button>
      </div>
    </b-container>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex'
import AdditionalInformationForm from "@/components/AdditionalInformationForm"

export default {
  name: 'Home',
  props: {activeStep: Number},
  components: {AdditionalInformationForm},
  data: function () {
    return {
      lastHoveredServiceCategory: 0,
      hoveredServiceCategory: 0,
      serviceSelectionStepPre: 1,
      serviceSelectionStepCor: 1,
    }
  },
  methods: {
    ...mapMutations([
      'setSelectedTrainCategory',
      'toggleSelectedService',
      'resetSelectedServices'
    ]),
    ...mapActions([
      'addServicesToCart',
    ]),
    scrollFix: function (hashbang) {
      location.hash = hashbang;
    },
    chooseDate() {
      this.$router.push('/choose-date')
    },
    enterServiceCategory(serviceCategory) {
      if (this.lastHoveredServiceCategory !== serviceCategory) {
        // this.resetSelectedServices()
      }
      this.hoveredServiceCategory = serviceCategory
    },
    leaveServiceCategory(serviceCategory) {
      this.lastHoveredServiceCategory = serviceCategory
      this.hoveredServiceCategory = 0
    },
    scrollToStep2() {
      const href = document.getElementById("step-2");
      this.$refs.content.scrollTop = href.offsetTop
    },
    scrollIntoView(event) {
      event.preventDefault()
      const href = event.target.getAttribute('href')
      const el = href ? document.querySelector(href) : null
      if (el) {
        this.$refs.content.scrollTop = el.offsetTop
      }
    }
  },
  mounted() {
    setTimeout(() => this.scrollFix(this.$route.hash), 1);
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      'availableTrainCategories',
      'selectedTrainCategory',
      'availableServiceCategories',
      'selectedServices',
      'servicesByCategory',
      'serviceAvailable',
      'canBeAddedToCart',
      'additionalServiceInformation',
      'orderItems'
    ]),
    maintenanceLevel: {
      get() {
        return this.$store.state.additionalServiceInformation.maintenanceLevel
      },
      set(value) {
        return this.$store.commit("setMaintenanceLevel", value)
      }
    },
    miscCor: {
      get() {
        return this.$store.state.additionalServiceInformation.miscCor
      },
      set(value) {
        return this.$store.commit("setMiscCor", value)
      }
    },
    miscPre: {
      get() {
        return this.$store.state.additionalServiceInformation.miscPre
      },
      set(value) {
        return this.$store.commit("setMiscPre", value)
      }
    },
  }
}
</script>
<style lang="scss">
.hero-wrapper {
  min-height: 500px;
  background-image: url("../assets/header.jpg");
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  background-position: bottom center;

  &:before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
  }
}

.hero-content {
  margin-top: 150px;
  @media(min-width: 767px) {
    margin-top: 40px;
  }

  .hero-headline {
    position: relative;
    padding-left: 30px;
    margin-bottom: 30px;

    &:before {
      content: " ";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 5px;
      background-image: linear-gradient(179deg, #00FFB9 0%, #00E6DC 100%);
    }
  }

  color: white;
}

.hero {
  min-height: 500px;
  position: relative;
}


.headline {
  position: relative;
  padding-left: 25px;
  margin-bottom: 30px;

  &:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background-image: linear-gradient(179deg, #00FFB9 0%, #00E6DC 100%);
  }
}

.category-btn {
  margin-left: 0.5rem;

  @media screen and (min-width: 600px) {
    &:first-of-type {
      margin-left: 0;
    }
  }
}

.navigation-dots {
  position: fixed;
  right: 5px;
  top: 50%;
  height: 50px;
  margin-top: -25px;
  line-height: 0;

  .navigation-dot {
    background: rgba(255, 255, 255, 0.35);
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50px;

    &:hover {
      background: rgba(255, 255, 255, 1);
    }
  }

  .active {
    .navigation-dot {
      background: rgba(255, 255, 255, 1);
    }
  }
}

.card-text.card-margin {
  margin-top: -25px;
}
</style>
