<template>
  <div>

    <b-sidebar
        id="sidebar-backdrop"
        :visible="showCart"
        backdrop
        backdrop-variant="dark"
        right
        shadow
        title="Anfragekorb"
        @hidden="hideCart"
    >
      <template #footer="{ hide }">
        <div class="d-block bg-grey text-light align-items-center px-3 py-2">
          <b-button class="w-100 mb-1" variant="outline-dark"
                    @click="goToServices(hide)">
            weiteren Service wählen
          </b-button>
          <b-button :disabled="orderItems.length === 0" class="w-100" variant="primary" @click="chooseDate">Datum
            wählen
          </b-button>
        </div>
      </template>

      <div v-if="orderItems.length === 0" class="px-3 py-2">
        Noch keine Services ausgewählt
      </div>
      <div v-else class="p-3">

        <h6 class="mb-4">Ihre ausgewählten Services</h6>
        <b-list-group>
          <b-list-group-item v-for="(orderItem,index) in orderItems" :key="index"
                             class="flex-column align-items-start"
          >
            <div class="d-flex w-100 justify-content-between">
              <h6 class="mb-1">{{ orderItem.name }}</h6>

            </div>

            <small>

              <span v-if="orderItem.additionalServiceInformation.maintenanceLevel !== ''">
                  Instandhaltungsstufe: {{ orderItem.additionalServiceInformation.maintenanceLevel }}<br/>
                </span>
              <span v-if="orderItem.additionalServiceInformation.miscPre !== ''">
                 Sonstiges: {{ orderItem.additionalServiceInformation.miscPre }}<br/>
                </span>
              <span v-if="orderItem.additionalServiceInformation.miscCor !== ''">
                 Leistung: {{ orderItem.additionalServiceInformation.miscCor }}<br/>
                </span>
            </small>
            <small class="justify-content-between">Datum:
              <span v-if="orderItem.date === ''">Noch nicht ausgewählt</span>
              <span v-else-if="orderItem.proposeDate">
                Terminvorschlag erwünscht
              </span>
              <span v-else>
                {{ orderItem.date.start|moment('DD.MM.YYYY') }} -
                {{ orderItem.date.end|moment('DD.MM.YYYY') }}
              </span>
              <b-icon-trash class="text-danger float-right mt-2 cursor-pointer"
                            @click="removeCartItem(index)"></b-icon-trash>
            </small>
          </b-list-group-item>
        </b-list-group>
        <div v-if="hasTrainInformation">
          <hr class="mt-5">
          <h6 class="mb-4 mt-2">Ihr Schienenfahrzeug</h6>
          <b-list-group>
            <b-list-group-item>
              <small class="justify-content-between">
                     <span class="icon-Edit float-right mt-2 cursor-pointer" @click="editTrainInformation"
                     ></span>
                <span v-if="selectedTrainCategoryName !== ''"><strong>Kategorie:</strong> {{
                    selectedTrainCategoryName
                  }}<br/></span>
                <span v-if="trainInformation.trainType !== ''"><strong>Zugtyp:</strong>
                  {{ trainInformation.trainType }}<br/></span>
                <span v-if="trainInformation.countCarriage !== ''"><strong>Anzahl Wagenkästen:</strong> {{
                    trainInformation.countCarriage
                  }}<br/></span>
                <span v-if="trainInformation.trainLength !== ''"><strong>Gesamtlänge Fahrzeug:</strong> {{
                    trainInformation.trainLength
                  }}<br/></span>
                <span v-if="trainInformation.trainGauge !== ''"><strong>Spurweite:</strong> {{
                    trainGaugeLabel(trainInformation.trainGauge)
                  }}<br/></span>

              </small>
            </b-list-group-item>
          </b-list-group>
        </div>
      </div>
    </b-sidebar>

  </div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex'

export default {
  name: 'TheCart',
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      'showCart',
      'orderItems',
      'selectedTrainCategoryName',
      'trainInformation',
      'hasTrainInformation',
      'hasAdditionalServiceInformation',
      'trainGaugeLabel'
    ])
  },
  methods: {
    ...mapMutations([
      'hideCart',
      'removeCartItem'
    ]),
    editTrainInformation() {
      this.hideCart()
      this.$router.push("/#step-2")
    },
    chooseDate() {
      this.$router.push('/choose-date')
    },
    async goToServices(hide) {
      if (this.$route.path !== '/') {
        await this.$router.push('/');
      }
      hide()
      const href = document.getElementById("step-services");
      window.scrollTo(0, href.offsetTop);
    }
  }
}
</script>
