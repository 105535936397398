const config = {
    production: {
        API_URI: '/api',
        ADOBEDTM: 'https://assets.adobedtm.com/5dfc7d97c6fb/29183ec46c58/launch-e3f8f18d2720.min.js'
    },
    development: {
        API_URI: 'http://localhost:8082/api',
        ADOBEDTM: 'https://assets.adobedtm.com/5dfc7d97c6fb/29183ec46c58/launch-2eb9b6320d93-staging.min.js '
    },
};

export const getConfig = env => config[env] || config.development;
