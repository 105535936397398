import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Meta from 'vue-meta'
import store from '../store'

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        beforeEnter(to, from, next) {
            store.commit('setActiveStep', 1)
            next();
        }
    },
    {
        path: '/choose-date',
        name: 'Choose Date',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "choosedate" */ '../views/ChooseDate.vue'),
        beforeEnter(to, from, next) {
            store.commit('setActiveStep', 2)
            next();
        }
    },
    {
        path: '/enter-address',
        name: 'Enter Address',
        component: () => import(/* webpackChunkName: "enteraddress" */ '../views/EnterAddress.vue'),
        beforeEnter(to, from, next) {
            store.commit('setActiveStep', 3)
            next();
        }
    },
    {
        path: '/success',
        name: 'Success',
        component: () => import(/* webpackChunkName: "enteraddress" */ '../views/SuccessPage.vue'),
        beforeEnter(to, from, next) {
            store.commit('setActiveStep', 4)
            next();
        }
    },
    {
        path: "*",
        component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
    },
    // {
    //     path: '*',
    //     beforeEnter() {
    //         window.location = `/404.html`
    //     }
    // }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
