<template>
  <div>

    <b-navbar fixed="top" type="dark">
      <b-container>
        <b-navbar-brand style="cursor:pointer;" @click="$router.push('/')">
          <b-img src="../assets/logo-light.png" width="120"/>
        </b-navbar-brand>
        <div class="platform-desc">
          My Rail Services
        </div>
        <!--        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>-->

        <b-navbar-nav class="ml-auto">
          <b-nav-item href="mailto:myrailservices.mobility@siemens.com?subject=MyRailServices%20Anfrage" right
          >

            <span class="icon-Email"></span>
            <span class="d-none d-md-inline" style="position: relative;
    font-size: 1rem;
    top: -5px;
    left: 3px;">Kontakt</span>
          </b-nav-item>
          <b-nav-item href="#" right @click="openCart">
            <span class="icon-Shopping-cart header--shopping-cart"></span>
            <b-badge v-if="orderItems.length" pill style="border-radius: 52px;
    padding: 0;
    height: 12px;
    width: 12px;
    line-height: 12px;
    font-size: 8px;"
                     variant="primary"
            >{{ orderItems.length }}
            </b-badge>
          </b-nav-item>

        </b-navbar-nav>
      </b-container>
      <b-container class="header__subnavigation">
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item :active="activeStep === 1" @click="$router.push('/')">
              1.
              Service
            </b-nav-item>
            <b-nav-item :active="activeStep === 2" :disabled="orderItems.length === 0"
                        @click="$router.push('/choose-date')">2.
              Termin
            </b-nav-item>
            <b-nav-item :active="activeStep === 3" :disabled="!datesSet"
                        @click="$router.push('/enter-address')">3.
              Daten
            </b-nav-item>
            <b-nav-item :active="activeStep === 4" :disabled="activeStep < 4" href="#">4. Bestätigung
            </b-nav-item>
          </b-navbar-nav>

          <!-- Right aligned nav items -->

        </b-collapse>
      </b-container>
    </b-navbar>

  </div>
</template>
<script>
import {mapGetters} from 'vuex'

export default {
  name: 'TheHeader',
  methods: {
    openCart() {
      this.$store.commit('toggleShowCart')
    }
  },
  computed: {
    ...mapGetters([
      'orderItems',
      'activeStep',
      'datesSet'
    ])
  }
}
</script>
<style lang="scss">
.navbar-dark .navbar-nav .nav-link {
  color: white;
  font-weight: normal !important;
}

.navbar-expand {
  flex-flow: row wrap;
  padding-top: 15px;
}

nav.navbar {
  z-index: 100;

  .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .navbar-nav .nav-link {
    .icon-Email {
      font-size: 1.5rem;
    }
  }

  .navbar-nav.icon-bar .nav-link span {
    color: white;
    font-weight: normal;
  }

  .platform-desc {
    color: white;
    margin-left: 1rem;
  }

  .navbar-brand {
    padding-top: 0;
  }
}

header .nav-link.active {
  //border-bottom: 1px solid #00FFB9;
}

.header--shopping-cart {
  font-size: 1.5rem;
}

.navbar-expand .navbar-nav .nav-item:first-child .nav-link {
  padding-left: 0;
}

.navbar-expand .navbar-nav .nav-item .nav-link {
  font-weight: bold;
}

@media screen and (max-width: 1023px) {
  .header__subnavigation {
    display: none !important;
  }
}
</style>
