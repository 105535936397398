<template>
  <div>
    <h5 class="mt-5">Weitere Informationen</h5>
    <b-row>
      <b-col>
        <b-form inline>

          <b-form-group
              id="input-group-1"
              class="mt-2 floating"
              label-for="input-1"
          >
            <b-form-input
                id="input-trainType"
                v-model="trainType"
                class="border-top-0 border-right-0 border-left-0"
                placeholder="Zugtyp / Baureihe"
                required
                type="text"
            ></b-form-input>
            <label for="input-trainType">Zugtyp / Baureihe</label>
          </b-form-group>
          <b-form-group
              id="input-group-1"
              class="mt-2 floating ml-sm-3"
          >
            <b-form-input
                id="input-countCarriage"
                v-model="countCarriage"
                class="border-top-0 border-right-0 border-left-0"
                placeholder="Anzahl Wagenkasten"
                required
                type="text"
            ></b-form-input>
            <label for="input-countCarriage">Anzahl Wagenkasten</label>
          </b-form-group>

          <b-form-group
              id="input-group-1"
              class="mt-2 floating ml-sm-3"
          >
            <b-form-input
                id="input-1"
                v-model="trainLength"
                class="border-top-0 border-right-0 border-left-0 "
                placeholder="Gesamtlänge Fahrzeug in m"
                required
                style="min-width:250px;"
                type="text"
            ></b-form-input>
            <label>Gesamtlänge Fahrzeug in m</label>
          </b-form-group>
          <div class="w-100 mb-3 mt-5">
            <b-form-group v-slot="{ ariaDescribedby }" label="Spurweite">
              <b-form-radio-group
                  id="radio-group-1"
                  v-model="trainGauge"
                  :aria-describedby="ariaDescribedby"
                  :options="availableGauges"
                  name="radio-options"
              ></b-form-radio-group>
              <small>
                <b-link v-if="trainGauge !== ''" href="#" @click="resetTrainGauge">Auswahl aufheben</b-link>
                <span v-else>&nbsp;</span>
              </small>
            </b-form-group>
          </div>
        </b-form>
      </b-col>
    </b-row>

  </div>
</template>
<script>
import {mapGetters} from "vuex"

export default {
  name: 'AdditionalInformationForm',
  methods: {
    resetTrainGauge() {
      this.$store.commit("setTrainGauge", "")
    }
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      'availableGauges'
    ]),
    trainType: {
      get() {
        return this.$store.state.trainInformation.trainType
      },
      set(value) {
        return this.$store.commit("setTrainType", value)
      }
    },
    countCarriage: {
      get() {
        return this.$store.state.trainInformation.countCarriage
      },
      set(value) {
        return this.$store.commit("setCountCarriage", value)
      }
    },
    trainLength: {
      get() {
        return this.$store.state.trainInformation.trainLength
      },
      set(value) {
        return this.$store.commit("setTrainLength", value)
      }
    },
    trainGauge: {
      get() {
        return this.$store.state.trainInformation.trainGauge
      },
      set(value) {
        return this.$store.commit("setTrainGauge", value)
      }
    }
  }
}
</script>
