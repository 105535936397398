<template>
  <div id="app">
    <header id="nav">
      <the-header></the-header>
    </header>
    <router-view/>
    <footer>
      <the-footer></the-footer>
    </footer>
    <the-cart></the-cart>
  </div>
</template>
<script>
import TheHeader from '@/components/TheHeader.vue'
import TheFooter from '@/components/TheFooter.vue'
import TheCart from '@/components/TheCart.vue'
import {getConfig} from "./config";

const config = getConfig(process.env.NODE_ENV);
export default {
  name: 'App',
  head: {
    script: [
      {type: 'text/javascript', src: config.ADOBEDTM, async: true}, // Insert in body
    ]
  },
  metaInfo: {
    title: 'My Rail Services',
    titleTemplate: '%s | Siemens Mobility',

  },
  mounted() {

  },
  methods: {
    handleScroll() {
      let header = document.querySelector(".fixed-top");
      if (window.scrollY > 10 && !header.className.includes('fixed-bg')) {
        header.classList.add('fixed-bg');
      } else if (window.scrollY < 10) {
        header.classList.remove('fixed-bg');
      }
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);

  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  components: {
    TheHeader,
    TheFooter,
    TheCart
  }
}
</script>
<style lang="scss">
.fixed-bg {
  background: rgb(0, 0, 40);
}
</style>
