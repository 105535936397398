<template>

  <b-container class="mb-5 mt-5 footer-row">
    <b-row>
      <b-col>
        <p class=" footer-indented">
          <strong>Weitere Informationen</strong><br/><br/>
          Unsere Serviceleistungen rund um die Inspektion und Instandsetzung Ihrer Schienenfahrzeuge erbringen wir in
          unserem Rail Service Center Dortmund-Eving – einer der modernsten Zugwerkstätten ihrer Art für
          Schienenfahrzeuge.
          <a class="footer-link"
             href="https://www.mobility.siemens.com/global/de/portfolio/schiene/services/maintenance-services/rail-service-center-dortmund.html"
             target="_blank">
            Mehr zum Rail Service Center Dortmund-Eving
          </a>
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="auto">
        <a href="https://www.mobility.siemens.com/global/de.html"> Siemens Mobility globale Website</a><br/>

      </b-col>
      <b-col><span class="footer-copyright">&copy; Siemens 1996-2021</span></b-col>
      <b-col class="footer-nav" md="auto">
        <a class="text-center d-block d-md-inline"
           href="https://www.mobility.siemens.com/global/de/allgemein/impressum.html" right
           target="_blank">
          Impressum
        </a>
        <a class="text-center d-block d-md-inline"
           href="https://www.mobility.siemens.com/global/de/allgemein/datenschutz.html" right
           target="_blank">
          Datenschutz
        </a>
        <a class="text-center d-block d-md-inline"
           href="https://www.mobility.siemens.com/global/de/allgemein/cookie-richtlinien.html" right
           target="_blank">
          Cookie Richtlinien
        </a>
        <a class="text-center d-block d-md-inline"
           href="https://www.mobility.siemens.com/global/de/allgemein/nutzungsbedingungen.html" right
           target="_blank">
          Nutzungsbedingungen
        </a>
      </b-col>
    </b-row>
  </b-container>

</template>
<script>
export default {
  name: 'TheFooter',
}
</script>
<style lang="scss">
.footer-row {
  font-size: 0.88rem;
  color: rgba(255, 255, 255, 1);
  @media screen and (max-width: 1023px) {
    text-align: center;
    padding: 20px 0;
  }

  .col {
    &:first-child {
      //padding-left: 0;

      a {
        padding-left: 0;
      }
    }
  }

  .footer-nav {
    a {
      margin: 0 15px;
      padding: 0;
      @media screen and (max-width: 1023px) {
        padding-top: 10px;
      }

      &:hover {
        border-bottom: 1px solid white;
      }
    }
  }

  a {
    color: rgba(255, 255, 255, 1);
    display: inline-block;
    padding: 0 10px;

    &:hover {
      color: rgba(255, 255, 255, 1);
      text-decoration: none;
    }
  }

  .footer-indented {
    margin-left: 10rem;
    margin-right: 10rem;
    margin-bottom: 8rem;
    margin-top: 4rem;
    @media screen and (max-width: 991px) {
      margin-left: 5rem;
      margin-right: 5rem;
    }
    @media screen and (max-width: 768px) {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .footer-link {
      color: #00FFB9 !important;
      margin-top: 1rem;
      font-weight: bold;
    }
  }

}

</style>
