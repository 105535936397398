import Vue from 'vue'
import Vuex from 'vuex'
import {v4 as uuidv4} from 'uuid';
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        activeStep: 1,
        availableTrainCategories: [
            // {_id: 1, name: "Hochgeschwindigkeitszug"},
            // {_id: 2, name: "Regionalbahn"},
            // {_id: 3, name: "Reisezugwagen"},
            // {_id: 4, name: "Straßenbahn"},
            // {_id: 5, name: "Lokomotive"},
            // {_id: 6, name: "Sonderfahrzeuge"},
        ],
        selectedTrainCategory: 0,
        trainInformation: {
            trainType: "",
            countCarriage: "",
            trainLength: "",
            trainGauge: ""
        },
        availableGauges: [
            {
                text: "Normalspur",
                value: "normal"
            },
            {
                text: "Meterspur",
                value: "meter"
            }
        ],
        additionalServiceInformation: {
            maintenanceLevel: "",
            miscPre: "",
            miscCor: "",
        },
        availableServiceCategories: [],
        availableServices: [],
        selectedServices: [],
        showCart: false,
        cart: {
            items: [],
            comment: ""
        },
        client: {}
    },
    mutations: {
        setActiveStep(state, step) {
            state.activeStep = step;
        },
        hideCart(state) {
            state.showCart = false;
        },
        toggleShowCart(state) {
            state.showCart = !state.showCart;
        },
        setSelectedTrainCategory(state, id) {
            state.selectedTrainCategory = id;
        },
        setTrainType(state, trainType) {
            state.trainInformation.trainType = trainType;
        },
        setMaintenanceLevel(state, maintenanceLevel) {
            state.additionalServiceInformation.maintenanceLevel = maintenanceLevel
        },
        setMiscPre(state, misc) {
            state.additionalServiceInformation.miscPre = misc
        },
        setMiscCor(state, misc) {
            state.additionalServiceInformation.miscCor = misc
        },
        setCountCarriage(state, countCarriage) {
            state.trainInformation.countCarriage = countCarriage;
        },
        setTrainLength(state, trainLength) {
            state.trainInformation.trainLength = trainLength;
        },
        setTrainGauge(state, trainGauge) {
            state.trainInformation.trainGauge = trainGauge;
        },
        toggleSelectedService(state, service) {
            state.selectedServices.indexOf(service) > -1 ? state.selectedServices.splice(state.selectedServices.indexOf(service), 1) : state.selectedServices.push(service)
        },
        addServiceToCart(state, cartItem) {
            state.cart.items.push(cartItem)
        },
        removeCartItem(state, index) {
            state.cart.items.splice(index, 1)
        },
        resetAdditionalServiceInformation(state) {
            state.additionalServiceInformation.miscCor = "";
            state.additionalServiceInformation.miscPre = "";
            state.additionalServiceInformation.maintenanceLevel = "";
        },
        resetSelectedServices(state) {
            state.additionalServiceInformation.miscCor = "";
            state.additionalServiceInformation.miscPre = "";
            state.additionalServiceInformation.maintenanceLevel = "";
            state.selectedServices = []
        },
        setShowCart(state, value) {
            state.showCart = value
        },
        setDateForItem(state, {id, dateRange}) {
            state.cart.items.filter(cartItem => cartItem._id === id)[0].date = dateRange
            state.cart.items.filter(cartItem => cartItem._id === id)[0].proposeDate = false
        },
        resetCart(state) {
            state.cart.items = []
        },
        setDateToProposalForItem(state, {id, value}) {
            state.cart.items.filter(item => item._id === id)[0].proposeDate = value;
            state.cart.items.filter(item => item._id === id)[0].date = "";
        },
        setTrainCategories(state, trainCategories) {
            state.availableTrainCategories = trainCategories;
        },
        setServiceCategories(state, serviceCategories) {
            state.availableServiceCategories = serviceCategories;
        },
        setServices(state, services) {
            state.availableServices = services;
        },
        setClient(state, client) {
            state.client = client
        },
        setCartComment(state, comment) {
            state.cart.comment = comment
        }
    },
    actions: {
        submitRequest: async function ({state}) {

            return await axios.post('/request', {
                cart: state.cart,
                client: state.client,
                trainInformation: {...state.trainInformation, trainCategory: state.selectedTrainCategory},
                additionalServiceInformation: state.additionalServiceInformation
            }).then(({data}) => {
                return data;
            })

        },
        fetchTrainCategories: async function ({commit}) {
            await axios.get('/train-category').then(({data}) => {
                commit('setTrainCategories', data)
            })
        },
        fetchServiceCategories: async function ({commit}) {
            await axios.get('/service-category').then(({data}) => {
                commit('setServiceCategories', data)
            })
        },
        fetchServices: async function ({commit}) {
            await axios.get('/service').then(({data}) => {
                commit('setServices', data)
            })
        },
        resetAllServicesExcept: async function ({state, getters}, category) {
            if (category !== '60fef249e84194651381b822') {
                state.additionalServiceInformation.miscPre = "";
                state.additionalServiceInformation.maintenanceLevel = "";
            }
            if (category !== '60fef254cc860b651b1f5d7b') {
                state.additionalServiceInformation.miscCor = "";
            }
            state.selectedServices = state.selectedServices.filter(selectedService => getters.serviceById(selectedService).category === category);
            // getters.selectedServices.forEach((selectedService, index) => {
            //     if (getters.serviceById(selectedService).category !== category) {
            //         state.selectedServices.splice(index, 1);
            //     }
            // })
        },
        async addServicesToCart({commit, getters, dispatch}, category) {
            await dispatch('resetAllServicesExcept', category);
            if (category === "60fef254cc860b651b1f5d7b") {
                let cartItem = {
                    _id: uuidv4(),
                    service: 30,
                    name: "Korrektive Instandhaltungsmaßnahmen",
                    additionalServiceInformation: {
                        miscPre: getters.additionalServiceInformation.miscPre,
                        miscCor: getters.additionalServiceInformation.miscCor,
                        maintenanceLevel: getters.additionalServiceInformation.maintenanceLevel,
                    },
                    date: "",
                    proposeDate: false
                }
                commit("addServiceToCart", cartItem)
            } else {
                if (getters.hasAdditionalServiceInformation) {
                    let cartItem = {
                        _id: uuidv4(),
                        service: 40,
                        name: "Präventive Instandhaltungsmaßnahmen",
                        additionalServiceInformation: {
                            miscPre: getters.additionalServiceInformation.miscPre,
                            miscCor: getters.additionalServiceInformation.miscCor,
                            maintenanceLevel: getters.additionalServiceInformation.maintenanceLevel,
                        },
                        date: "",
                        proposeDate: false
                    }
                    commit("addServiceToCart", cartItem);
                    commit("resetAdditionalServiceInformation");
                }


                getters.selectedServices.forEach(selectedService => {
                    let curService = getters.serviceById(selectedService);
                    let cartItem = {
                        _id: uuidv4(),
                        service: curService._id,
                        name: curService.name,
                        additionalServiceInformation: {
                            miscPre: getters.additionalServiceInformation.miscPre,
                            miscCor: getters.additionalServiceInformation.miscCor,
                            maintenanceLevel: getters.additionalServiceInformation.maintenanceLevel,
                        },
                        date: "",
                        proposeDate: false
                    }
                    commit("addServiceToCart", cartItem)
                });
            }
            commit("resetSelectedServices");
            commit("setShowCart", true);
        },
        resetState({commit}) {
            commit("resetSelectedServices");
            commit("resetCart");
        },

    },
    getters: {
        trainInformation: state => state.trainInformation,
        activeStep: state => state.activeStep,
        showCart: state => state.showCart,
        cartItemCount: state => state.cart.items.length,
        availableTrainCategories: state => state.availableTrainCategories,
        selectedTrainCategory: state => state.selectedTrainCategory,
        selectedTrainCategoryName: (state, getters) => (getters.selectedTrainCategory > 0) ? getters.availableTrainCategories.filter(category => category._id === getters.selectedTrainCategory)[0].name : "",
        availableServiceCategories: state => state.availableServiceCategories,
        selectedServices: state => state.selectedServices,
        orderItems: state => state.cart.items,
        servicesByCategory: (state) => (categoryId) => state.availableServices.filter(service => service.category === categoryId),
        serviceAvailable: (state, getters) => (serviceId) => (getters.serviceById(serviceId).availableIf.length === 0
            || getters.selectedServices.filter(service => getters.serviceById(serviceId).availableIf.indexOf(service) > -1).length > 0),
        serviceById: (state) => (serviceId) => state.availableServices.filter(service => service._id === serviceId)[0],
        hasAdditionalServiceInformation: (state) => state.additionalServiceInformation.miscPre !== "" || state.additionalServiceInformation.miscCor !== "" || state.additionalServiceInformation.maintenanceLevel !== "",
        additionalServiceInformation: (state) => state.additionalServiceInformation,
        datesSet: (state, getters) => (getters.orderItems.filter(orderItem => orderItem.date === "" && !orderItem.proposeDate).length === 0 && getters.orderItems.length > 0),
        hasTrainInformation: (state, getters) => (getters.trainInformation.trainType !== ""
            || getters.trainInformation.countCarriage !== ""
            || getters.trainInformation.trainLength !== ""
            || getters.selectedTrainCategory > 0),
        canBeAddedToCart: (state, getters) => (categoryId) => ((getters.selectedServicesInCategory(categoryId).length > 0) || (getters.hasAdditionalServiceInformation)),
        availableGauges: (state) => state.availableGauges,
        selectedServicesInCategory: (state, getters) => (categoryId) => {
            if (typeof getters.selectedServices !== "undefined" && typeof state.availableServices !== "undefined") {
                return getters.selectedServices.filter(selectedService => {
                    return getters.serviceById(selectedService).category === categoryId
                })
            } else {
                return []
            }
        },
        trainGaugeLabel: (state) => (value) => state.availableGauges.filter(gauge => gauge.value === value)[0].text
    },
})

