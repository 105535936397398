import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VCalendar from 'v-calendar';
import VueMeta from 'vue-meta'
import axios from "axios"
import {getConfig} from './config';
import VueHead from 'vue-head';

Vue.use(VueHead);

const config = getConfig(process.env.NODE_ENV);

Vue.use(VueMeta)
Vue.use(VCalendar);
Vue.use(require('vue-moment'));
Vue.config.productionTip = false
axios.defaults.baseURL = config.API_URI

new Vue({
    router,
    store,
    render: h => h(App),
    async created() {
        await this.$store.dispatch("fetchTrainCategories")
        await this.$store.dispatch("fetchServiceCategories")
        await this.$store.dispatch("fetchServices")
    },
}).$mount('#app')
